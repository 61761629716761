import { Redirect } from 'components';
import { ACCOUNT_OPTIONS_PATH, DD_PORTAL_URL } from 'configs';
import { AccountOptions } from 'pages';
import { FC } from 'react';
import { Route, Routes } from 'react-router';

export const LandingPagesRouting: FC = () => (
  <Routes>
    <Route path={ACCOUNT_OPTIONS_PATH} element={<AccountOptions />} />
    {/*
    <Route path={ACCOUNT_CONFIRMATION_PATH} element={<AccountConfirmation />} />
    <Route path={ACCOUNT_PATH} element={<AccountCreateEdit />} />
    <Route path={EMAIL_CONFIRMATION_PATH} element={<EmailConfirmation />} />
    <Route path={GROUP_INVITATION_PATH} element={<GroupJoin />} />
    */}
    <Route path="*" element={<Redirect to={DD_PORTAL_URL} />} />
  </Routes>
);
