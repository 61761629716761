import { getIsAuthenticated } from 'providers/auth/authState';
import { getAccessToken } from 'providers/auth/authToken';
import { useMemo } from 'react';

export const useGetApiConfig = (basePath?: string, includeBearer = true) => {
  const isAuthenticated = getIsAuthenticated();

  const apiConfig = useMemo(
    () => ({
      basePath,
      ...(isAuthenticated
        ? {
            accessToken: () => {
              const token = getAccessToken();
              return includeBearer ? `Bearer ${token}` : token;
            }
          }
        : {})
    }),
    [basePath, includeBearer, isAuthenticated]
  );

  return apiConfig;
};
