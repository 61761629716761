import {
  ClientExceptionEntity,
  ResponseError
} from '@DigitaleDoerfer/digitale-doerfer-api';

/**
 * Handles an error that occurred during an API call.
 *
 * @param {ResponseError} e - The error that occurred.
 * @throws {ResponseError} The original error that occurred.
 */
export const handleError = async (e: ResponseError) => {
  const error = (await e.response.json()) as ClientExceptionEntity;
  console.error('An error occurred. Please try again later.', error);
  throw e;
};

export const getError = async (e: ResponseError) => {
  const error = (await e.response.json()) as ClientExceptionEntity;
  return error;
};
