let accessToken: string | undefined = '';
export const getAccessToken = (): string => accessToken ?? '';
export const setAccessToken = (token?: string): void => {
  accessToken = token;
};

let refreshToken: string | undefined = '';
export const getRefreshToken = (): string => refreshToken ?? '';
export const setRefreshToken = (token?: string): void => {
  refreshToken = token;
};
