import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

export const queryKeys = {
  getPerson: 'getPerson',
  getGeoArea: 'getGeoArea',
  geoAreas: 'geoAreas'
} as const;
