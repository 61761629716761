import { State, StateView } from 'components';
import { useCollectUserDataAndSendMail, useDeletePerson } from 'hooks';
import { useAuth } from 'providers';
import { FC, useCallback, useMemo, useState } from 'react';
import { hasValue } from 'utils';

import './AccountOptions.scss';

export const AccountOptions: FC = () => {
  const { isAuthenticated, login, logout, updateCredentials } = useAuth();

  const [errors, setErrors] = useState<string[]>();

  const {
    mutate: collectUserDataAndSendMail,
    isPending: isDataPrivacyReportRequestLoading
  } = useCollectUserDataAndSendMail();

  const handleRequestDataPrivacyReport = useCallback(() => {
    setErrors([]);
    collectUserDataAndSendMail(
      {},
      {
        onSuccess: () => {
          setErrors([
            'Du erhältst innerhalb der nächsten 48 Stunden eine E-Mail mit einem Link zu deinen Daten.'
          ]);
        },
        onError: () => {
          setErrors([
            'Hoppla, etwas lief schief.\nBitte versuch es zu einem späteren Zeitpunkt erneut.'
          ]);
        }
      }
    );
  }, [collectUserDataAndSendMail]);

  const {
    mutate: deletePerson,
    isSuccess: isDeletePersonSuccess,
    isPending: isDeletePersonLoading
  } = useDeletePerson();

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const handleDeletePerson = useCallback(() => {
    setShowDeleteConfirmation(false);
    deletePerson(
      {},
      {
        onError: () => {
          setErrors([
            'Hoppla, etwas lief schief.\nBitte versuch es zu einem späteren Zeitpunkt erneut.'
          ]);
        }
      }
    );
  }, [deletePerson]);

  const isLoading = useMemo(
    () => isDataPrivacyReportRequestLoading || isDeletePersonLoading,
    [isDataPrivacyReportRequestLoading, isDeletePersonLoading]
  );

  if (isLoading) {
    return (
      <div className="account-options">
        <StateView state={State.Loading} />
      </div>
    );
  }

  return (
    <div className="account-options">
      {isAuthenticated ? (
        <>
          {isDeletePersonSuccess ? (
            <p className="delete-conformation">
              {`Dein Konto wurde gelöscht.\n\nSchade, dass du uns verlässt. Solltest du es dir irgendwann anders überlegen, heißen wir dich jederzeit wieder herzlich willkommen!\n\nViele Grüße\nDein Digitale-Dörfer-Team`}
            </p>
          ) : (
            <>
              {hasValue(errors) && (
                <div className="messages">
                  {errors?.map((errorMessage, index) => (
                    <div className="message" key={index}>
                      {errorMessage} <br />
                    </div>
                  ))}
                  <button
                    className="clear-button"
                    onClick={() => setErrors([])}
                  >
                    x
                  </button>
                </div>
              )}
              {showDeleteConfirmation && (
                <div className="messages">
                  <div className="message">
                    Dein Konto und sämtliche Daten werden unwiderruflich
                    gelöscht. Bist du sicher?
                  </div>

                  <button className="red-button" onClick={handleDeletePerson}>
                    Konto löschen
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              )}
              <button className="option" onClick={updateCredentials}>
                {'> Passwort zurücksetzen'}
              </button>
              <button
                className="option"
                onClick={handleRequestDataPrivacyReport}
              >
                {'> Datenbericht anfordern'}
              </button>
              <button
                className="option"
                onClick={() => {
                  setErrors([]);
                  setShowDeleteConfirmation(true);
                }}
              >
                {'> Konto löschen'}
              </button>
              <br />
              <button className="option" onClick={() => logout()}>
                {'> Abmelden'}
              </button>
            </>
          )}
        </>
      ) : (
        <div>
          <div className="login">
            <h2 className="title">Verwalte dein Digitale-Dörfer-Konto</h2>
            <button className="red-button" onClick={() => login()}>
              Anmelden
            </button>
          </div>
          <div>
            Melde dich an, um folgende Funktionen für die Apps DorfFunk,
            BayernFunk, DorfApp Dreis-Brück und PfaffFunk von Fraunhofer IESE zu
            nutzen:
            <p className="options-title">
              <b>Passwort zurücksetzen</b>
              Ändere das Passwort deines Kontos.
            </p>
            <p className="options-title">
              <b>Datenbericht anfordern</b>
              Fordere eine Übersicht deiner gespeicherten Daten und Aktivitäten
              an.
            </p>
            <p className="options-title">
              <b>Konto löschen</b>
              Lösche dein Konto und alle zugehörigen Daten dauerhaft.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
