import { QueryClientProvider } from '@tanstack/react-query';
import {
  ACCOUNT_CONFIRMATION_PATH,
  ACCOUNT_OPTIONS_PATH,
  EMAIL_CONFIRMATION_PATH,
  GROUP_INVITATION_PATH,
  queryClient
} from 'configs';
import { Layout } from 'layout';
import { AuthProvider } from 'providers';
import { FC, StrictMode, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router';

export const LandingPages: FC = () => {
  const location = useLocation();

  const title = useMemo(() => {
    if (location.pathname.includes(ACCOUNT_CONFIRMATION_PATH)) {
      return 'Konto Bestätigung';
    } else if (location.pathname.includes(EMAIL_CONFIRMATION_PATH)) {
      return 'E-Mail Bestätigung';
    } else if (location.pathname.includes(GROUP_INVITATION_PATH)) {
      return 'Gruppenmitgliedschaft';
    } else if (location.pathname.includes(ACCOUNT_OPTIONS_PATH)) {
      return 'Konto-Optionen';
    }
  }, [location.pathname]);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AuthProvider>
        <StrictMode>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Outlet />
            </Layout>
          </QueryClientProvider>
        </StrictMode>
      </AuthProvider>
    </HelmetProvider>
  );
};
