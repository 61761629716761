import { Redirect } from 'components';
import { DD_PORTAL_URL } from 'configs';
import { LandingPages } from 'LandingPages';
import { LandingPagesRouting } from 'LandingPagesRouting';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router';
import './theme/index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<LandingPages />}>
      <Route index element={<Redirect to={DD_PORTAL_URL} />} />
      <Route path="*" element={<LandingPagesRouting />} />
    </Route>
  )
);

root.render(<RouterProvider router={router} />);
