import { Loader } from 'components';
import {
  APP_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL
} from 'configs';
import Keycloak, { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { handleError } from 'utils';
import { setIsAuthenticated } from './authState';
import { setAccessToken } from './authToken';
import { AuthContext, AuthContextValues, keycloakInitOptions } from './useAuth';

const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [tokenParsed, setTokenParsed] = useState<KeycloakTokenParsed>();
  const [authProfile, setAuthProfile] = useState<KeycloakProfile>();

  keycloak.onReady = () => setIsReady(true);

  keycloak.onAuthSuccess = () =>
    keycloak
      .loadUserProfile()
      .then((profile) => {
        setAuthProfile(profile);
      })
      .catch(handleError);

  keycloak.onAuthRefreshSuccess = () => {
    setAccessToken(keycloak.token);
    setTokenParsed(keycloak.tokenParsed);
  };

  keycloak.onTokenExpired = () => keycloak.updateToken(5).catch(handleError);

  // Called if there was an error while trying to refresh the token.
  keycloak.onAuthRefreshError = () => {
    keycloak.clearToken();
    keycloak.logout();
  };

  keycloak.onAuthLogout = () => {
    setAuthenticated(false);
    setIsAuthenticated(false);
    setAccessToken(undefined);
    setTokenParsed(undefined);
    setAuthProfile(undefined);
  };

  useEffect(() => {
    const initializeKeycloak = async () => {
      await keycloak
        .init(keycloakInitOptions)
        .then((value) => {
          setAccessToken(keycloak.token);
          setTokenParsed(keycloak.tokenParsed);
          setAuthenticated(value);
          setIsAuthenticated(value);
        })
        .catch(handleError);
    };

    initializeKeycloak();
  }, []);

  const login = useCallback(
    (redirectUri = APP_URL) => keycloak.login({ redirectUri }),
    []
  );

  const logout = useCallback(
    (redirectUri = APP_URL) => keycloak.logout({ redirectUri }),
    []
  );

  const updateCredentials = useCallback(async () => {
    const loginConfigureOTPUrl = await keycloak.createLoginUrl({
      redirectUri: window.location.href,
      loginHint: authProfile?.email,
      action: 'UPDATE_PASSWORD'
    });
    window.location.assign(loginConfigureOTPUrl);
  }, [authProfile?.email]);

  const authValues: AuthContextValues = useMemo(
    () => ({
      isReady,
      isAuthenticated,
      authProfile,
      tokenParsed,
      login,
      logout,
      updateCredentials
    }),
    [
      isReady,
      isAuthenticated,
      authProfile,
      tokenParsed,
      login,
      logout,
      updateCredentials
    ]
  );

  return (
    <AuthContext.Provider value={authValues}>
      {isReady ? children : <Loader />}
    </AuthContext.Provider>
  );
};
