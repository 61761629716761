import { Layout } from 'layout';
import { FC } from 'react';
import { State } from './States';
import { StateView } from './StateView';

import './Loader.scss';

export const Loader: FC = () => (
  <Layout>
    <div className="loader">
      <StateView state={State.Loading} />
    </div>
  </Layout>
);
