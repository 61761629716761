import { FC, useEffect } from 'react';

type RedirectProps = {
  to: string;
};

export const Redirect: FC<RedirectProps> = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return <></>;
};
