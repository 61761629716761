import { ErrorIcon, SuccessIcon, WarningIcon } from 'assets';
import { FC } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { State } from './States';

import './StateView.scss';

type StateViewProps = {
  state: State;
  messages?: string[];
};

export const StateView: FC<StateViewProps> = ({
  state = State.Loading,
  messages = ['Einen Moment bitte...']
}) => (
  <div className="state">
    {state === State.Loading && (
      <span>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="1.25"
          width="96"
          visible={true}
        />
      </span>
    )}
    {state === State.Success && <SuccessIcon />}
    {state === State.Warning && <WarningIcon />}
    {state === State.Error && <ErrorIcon />}

    <p>
      {messages?.map((message, index) => (
        <span key={index}>
          {message} <br />
        </span>
      ))}
    </p>
  </div>
);
