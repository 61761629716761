import {
  KeycloakInitOptions,
  KeycloakProfile,
  KeycloakTokenParsed
} from 'keycloak-js';
import { createContext, useContext } from 'react';

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  pkceMethod: 'S256',
  responseMode: 'fragment',
  redirectUri: window.location.href
};

export type AuthContextValues = {
  isReady: boolean;
  isAuthenticated: boolean;
  authProfile?: KeycloakProfile;
  tokenParsed?: KeycloakTokenParsed;
  login: (redirectUri?: string) => void;
  logout: (redirectUri?: string) => void;
  updateCredentials: () => void;
};

const defaultValues: AuthContextValues = {
  isReady: false,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  updateCredentials: () => {}
};

export const AuthContext = createContext<AuthContextValues>(defaultValues);
export const useAuth = () => useContext(AuthContext);
