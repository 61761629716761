import { DDLogo } from 'assets';
import { FC, PropsWithChildren } from 'react';

import './Layout.scss';

export const Layout: FC<PropsWithChildren> = ({ children }) => (
  <div className="layout">
    <div className="content">
      <div className="header">
        <img className="dd-logo" alt="Digitale Dörfer Logo" src={DDLogo} />
      </div>

      <div className="main">
        {children}

        <p className="support">
          <a href="https://support.digitale-doerfer.de/portal/de/home">
            Support
          </a>
          <a href="https://www.digitale-doerfer.de/datenschutzinformation/">
            Datenschutz
          </a>
          <a href="https://www.digitale-doerfer.de/registrierungsbedingungen/">
            AGB
          </a>
          <a href="https://www.digitale-doerfer.de/impressum/">Impressum</a>
        </p>
      </div>
    </div>
  </div>
);
