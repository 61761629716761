import {
  CollectUserDataAndSendMailRequest,
  DeletePersonRequest,
  SharedDataprivacyApi
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { useCallback, useMemo } from 'react';
import { handleError } from 'utils';
import { useGetDDServiceApiConfiguration } from './dd.service';

export const useSharedDataprivacyApi = () => {
  const configuration = useGetDDServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new SharedDataprivacyApi(configuration),
    [configuration]
  );

  const collectUserDataAndSendMail = useCallback(
    (requestParameters: CollectUserDataAndSendMailRequest) =>
      apiInstance
        .collectUserDataAndSendMail(requestParameters)
        .catch(handleError),
    [apiInstance]
  );

  const deletePerson = useCallback(
    (requestParameters: DeletePersonRequest) =>
      apiInstance.deletePerson(requestParameters).catch(handleError),
    [apiInstance]
  );

  return {
    collectUserDataAndSendMail,
    deletePerson
  };
};
